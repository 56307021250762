/* 
 * Configurações de ambiente para o modo de desenvolvimento.
 * Este arquivo pode ser substituído durante o build utilizando o array `fileReplacements`.
 * Por padrão, o comando `ng build` utiliza este arquivo.
 * O comando `ng build --prod` o substitui por `environment.prod.ts`.
 * A lista de substituições de arquivos pode ser encontrada no arquivo `angular.json`.
 * 
 * Importante: No modo de desenvolvimento, você pode importar o seguinte arquivo 
 * para ignorar rastreamentos de erro relacionados ao Zone, como `zone.run` e `zoneDelegate.invokeTask`.
 * Esta importação deve estar comentada no modo de produção, pois pode impactar negativamente o desempenho.
 * 
 */

// import 'zone.js/plugins/zone-error';  // Incluído com o Angular CLI.

export const environment = {
  production: false,
  authTokenKey: 'erpValorSession',
  // URL da API de homologação
  sys_url: 'https://sistema-dev.valorfinanciamentos.com.br',
  // base_url: 'http://localhost:9000/api',
  base_url: 'https://sistema-dev.valorfinanciamentos.com.br/api-laravel/public/index.php/api',
};

/*
 * Importante: Abaixo estão os links para o ambiente de desenvolvimento local.
 * 
 * - base_url: 'http://localhost:9000/api'
 * 
 * Nota: Não é necessário modificar a variável `sys_url`, pois ela é utilizada
 * exclusivamente para acesso a relatórios e arquivos que não estarão disponíveis
 * no ambiente local. Portanto, podemos manter o link de homologação para essa variável.
 * 
 * Atenção: Se as alterações envolverem apenas layout ou ajustes na pasta `/reports`, 
 * não será necessário alterar a variável `base_url`.
 * 
 */

