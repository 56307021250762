import { Injectable } from "@angular/core";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public swal = Swal;

  constructor(
    public toast: ToastrService
  ) { }

  alertInfo(msg = '', titulo = 'Ops!') {
    Swal.fire({
      icon: 'info',
      title: titulo,
      html: msg,
    });
  }

  alertErro(msg = '', titulo = 'Ops!') {
    Swal.fire({
      icon: 'error',
      title: titulo,
      html: msg,
    });
  }
  
  alertNet() {
    Swal.fire({
      icon: 'error',
      title: 'Falha na conexão',
      html: 'Verifique a conexão e tente novamente!',
      allowOutsideClick: false,
    }).then(resp => {
      if (resp.value) {
        location.reload();
      }
    });
  }

  public toastInfo(msg = '', title = '') {
    this.toast.info(msg, title);
  }
  public toastError(msg = '', title = '') {
    this.toast.error(msg, title);
  }
  public toastSuccess(msg = '', title = '') {
    this.toast.success(msg, title);
  }
}
